import { SvgIcon } from '@mui/material';

export function LogoFooterBetGecko(props: any) {
  return (
    <SvgIcon
      width="281"
      height="40"
      viewBox="0 0 281 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_4266_121138)">
        <path
          d="M7.37 1.10999H25.43C30.53 1.10999 33.83 2.32999 35.33 4.76999C36.3399 6.57378 36.6191 8.69638 36.11 10.7C35.8193 12.2549 35.1748 13.722 34.2263 14.9879C33.2777 16.2537 32.0507 17.2843 30.64 18V18.11C34.12 19.67 35.4333 22.67 34.58 27.11L34.45 27.76C33.4833 32.72 30.28 36.1967 24.84 38.19C23.5481 38.6116 22.1989 38.8308 20.84 38.84H0L7.37 1.10999ZM16.24 9.10998L14.92 15.91H22.06C24.18 15.91 25.4833 14.67 25.97 12.19C26.0929 11.8533 26.1314 11.4916 26.0819 11.1366C26.0325 10.7816 25.8966 10.4442 25.6863 10.1539C25.4761 9.86365 25.1977 9.62944 24.8758 9.47184C24.5539 9.31424 24.1982 9.23806 23.84 9.24999C23.2093 9.14232 22.5697 9.09544 21.93 9.10998H16.24ZM13.44 23.45L12.06 30.54H20C20.7547 30.562 21.5009 30.3747 22.1557 29.9987C22.8105 29.6228 23.3485 29.0729 23.71 28.41C23.9797 27.8415 24.1843 27.2444 24.32 26.63C24.74 24.51 23.5633 23.45 20.79 23.45H13.44Z"
          fill="white"
        />
        <path
          d="M68.45 1.10999L66.7 10.05H50.1L49 15.77H64.2L62.6 23.85H47.4L46.24 29.78H62.85L61.08 38.84H34.08L41.49 1.10999H68.45Z"
          fill="white"
        />
        <path
          d="M98.1 1.10999L96.35 10.05H88.26L82.64 38.84H71.86L77.48 10.05H69.39L71.14 1.10999H98.1Z"
          fill="white"
        />
        <path
          d="M122.07 0.539989C129.103 0.539989 134 2.62332 136.76 6.78999L128.22 13.26C127.731 12.2386 126.961 11.3782 126 10.78C124.563 10.1449 122.999 9.85059 121.43 9.91999C117.797 9.91999 114.57 11.8967 111.75 15.85C110.944 17.1387 110.375 18.561 110.07 20.05C109.15 24.7167 110.59 28.0233 114.39 29.97C115.41 30.3856 116.499 30.6061 117.6 30.62H118.79C122.45 30.28 124.38 29.5967 124.58 28.57L125.3 24.91H117.86L119.39 17H137.72L133.43 39H125.34L125.57 37.81C122.96 38.7905 120.228 39.4096 117.45 39.65C116.51 39.79 115.52 39.9 114.45 39.97C109.703 39.97 105.893 38.4967 103.02 35.55C101.459 33.7435 100.327 31.6067 99.71 29.3C98.8309 26.2824 98.714 23.0938 99.37 20.02C100.43 14.58 103.597 9.57999 108.87 5.01999C112.646 2.09325 117.293 0.515961 122.07 0.539989Z"
          fill="#00EA6A"
        />
        <path
          d="M170.47 1.18999L168.72 10.13H152.11L151 15.85H166.2L164.62 23.94H149.42L148.26 29.87H164.87L163.1 38.92H136.1L143.47 1.18999H170.47Z"
          fill="#00EA6A"
        />
        <path
          d="M195.32 24.1H206.37C204.21 29.2667 199.96 33.6 193.62 37.1C190.517 38.6592 187.093 39.4708 183.62 39.47C180.769 39.5474 177.947 38.883 175.429 37.542C172.912 36.201 170.786 34.2294 169.26 31.82C167.106 28.1847 166.409 23.8684 167.31 19.74C168.643 12.82 172.813 7.28666 179.82 3.13999C183.405 1.09171 187.461 0.00972098 191.59 -7.91985e-06C194.172 -0.0368825 196.73 0.50541 199.074 1.587C201.419 2.66859 203.492 4.26197 205.14 6.24999C207.144 9.10552 208.194 12.5217 208.14 16.01H197.3C197.108 14.8553 196.661 13.758 195.992 12.7979C195.322 11.8378 194.447 11.0391 193.43 10.46C192.201 9.79753 190.826 9.45372 189.43 9.45999C185.69 9.45999 182.53 11.2933 179.95 14.96C179.004 16.3534 178.363 17.9313 178.07 19.59V19.7C177.678 21.2457 177.741 22.8717 178.25 24.3828C178.759 25.894 179.693 27.2263 180.94 28.22C182.207 29.1397 183.735 29.6302 185.3 29.62H186.16C189.727 29.64 192.78 27.8 195.32 24.1Z"
          fill="#00EA6A"
        />
        <path
          d="M223.22 1.18999L220.27 16.28L236.05 1.18999H248.34L231 17.47L241.69 38.92H228.89L222.46 24.92H222.36L217.76 29.12L215.85 38.93H205.39L212.76 1.19999L223.22 1.18999Z"
          fill="#00EA6A"
        />
        <path
          d="M277.16 5.87999C273.973 2.02665 269.587 0.103321 264 0.109987C258.337 0.13497 252.869 2.18113 248.58 5.87999C244.126 9.32655 241.059 14.2601 239.94 19.78C238.88 25.2133 239.947 29.85 243.14 33.69C244.94 35.8807 247.316 37.5257 250 38.44C252 34.44 256.48 24.61 252.66 22.27C250.92 21.63 247.25 22.27 247.25 22.27C247.25 22.27 244.14 21.27 245.63 16.59C247.12 11.91 251.54 16.59 251.54 16.59C251.54 16.59 254.54 20.19 256.34 18.11C258.14 16.03 255.45 9.71999 255.45 9.71999C255.45 9.71999 253.45 4.99999 257.67 4.19999C261.89 3.39999 260.54 9.71999 260.54 9.71999C260.54 9.71999 259.38 16.28 262.15 16.59C264.92 16.9 267.15 10.59 267.15 10.59C267.15 10.59 268.87 7.30999 271.75 10.19C274.63 13.07 269.75 16.35 269.75 16.35C269.75 16.35 264.24 19.14 264.8 21.35C265.7 24.35 270.53 24.35 270.53 24.35C270.53 24.35 275.53 24.75 273.24 30.03C270.95 35.31 267.41 28.65 267.41 28.65C267.41 28.65 264.5 26.27 263.24 27.23C259.629 30.6381 256.76 34.7537 254.81 39.32C255.29 39.32 255.81 39.38 256.29 39.38C261.953 39.355 267.422 37.3088 271.71 33.61C276.168 30.1636 279.232 25.2244 280.34 19.7C281.447 14.3333 280.387 9.72665 277.16 5.87999Z"
          fill="#00EA6A"
        />
      </g>
      <defs>
        <clipPath id="clip0_4266_121138">
          <rect width="280.76" height="40" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
