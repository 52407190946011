import { SvgIcon } from '@mui/material';

export function LogoBetGeckoMB(props: any) {
  return (
    <SvgIcon
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_4266_121106)">
        <mask
          id="mask0_4266_121106"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="41"
          height="41"
        >
          <path
            d="M20.0016 40.0032C31.0482 40.0032 40.0032 31.0482 40.0032 20.0016C40.0032 8.95503 31.0482 0 20.0016 0C8.95503 0 0 8.95503 0 20.0016C0 31.0482 8.95503 40.0032 20.0016 40.0032Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_4266_121106)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40.0032 20.0016C40.0032 31.0482 31.0482 40.0032 20.0016 40.0032C18.3556 40.0032 16.756 39.8044 15.2256 39.4294C17.8925 33.7681 22.2151 29.2276 23.2324 28.4562C24.5247 27.4725 27.4982 29.9124 27.4982 29.9124C27.4982 29.9124 31.1147 36.7178 33.4614 31.3204C35.8049 25.923 30.6968 25.4987 30.6968 25.4987C30.6968 25.4987 25.7623 25.4183 24.8365 22.3901C24.2643 20.1109 29.8963 17.2466 29.8963 17.2466C29.8963 17.2466 34.8373 13.897 31.8959 10.9523C28.9545 8.00772 27.1928 11.3606 27.1928 11.3606C27.1928 11.3606 24.9104 17.8285 22.0719 17.4974C19.2333 17.1663 20.4195 10.4637 20.4195 10.4637C20.4195 10.4637 21.7793 4.0119 17.4878 4.82199C13.1962 5.63208 15.2182 10.4605 15.2182 10.4605C15.2182 10.4605 17.9475 16.9188 16.128 19.0469C14.3085 21.175 11.2127 17.4974 11.2127 17.4974C11.2127 17.4974 6.68651 12.7558 5.16598 17.4974C3.64544 22.239 6.83117 23.3031 6.83117 23.3031C6.83117 23.3031 10.5762 22.6601 12.3604 23.3031C15.6977 25.3434 12.8747 32.9557 10.6824 37.7041C4.33006 34.3531 0 27.683 0 20.0016C0 8.95503 8.95503 0 20.0016 0C31.0482 0 40.0032 8.95503 40.0032 20.0016Z"
            fill="#00EA6A"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_4266_121106">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
