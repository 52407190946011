import { SvgIcon } from '@mui/material';

export function LogoBetGecko(props: any) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="225"
      height="32"
      viewBox="0 0 225 32"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_4266_121055)">
        <path
          d="M5.896 0.888H20.344C24.424 0.888 27.064 1.864 28.264 3.816C29.072 5.25904 29.2953 6.95711 28.888 8.56C28.6555 9.8039 28.1398 10.9776 27.381 11.9903C26.6222 13.003 25.6405 13.8275 24.512 14.4V14.488C27.296 15.736 28.3467 18.136 27.664 21.688L27.56 22.208C26.7867 26.176 24.224 28.9573 19.872 30.552C18.8385 30.8893 17.7591 31.0647 16.672 31.072H0L5.896 0.888ZM12.992 7.288L11.936 12.728H17.648C19.344 12.728 20.3867 11.736 20.776 9.752C20.8744 9.48266 20.9051 9.19329 20.8655 8.90929C20.826 8.62529 20.7173 8.35534 20.5491 8.12314C20.3808 7.89093 20.1582 7.70357 19.9007 7.57749C19.6431 7.4514 19.3586 7.39046 19.072 7.4C18.5674 7.31387 18.0557 7.27636 17.544 7.288H12.992ZM10.752 18.76L9.648 24.432H16C16.6038 24.4496 17.2007 24.2997 17.7245 23.999C18.2484 23.6982 18.6788 23.2583 18.968 22.728C19.1837 22.2732 19.3474 21.7955 19.456 21.304C19.792 19.608 18.8507 18.76 16.632 18.76H10.752Z"
          fill="white"
        />
        <path
          d="M54.76 0.888L53.36 8.04H40.08L39.2 12.616H51.36L50.08 19.08H37.92L36.992 23.824H50.28L48.864 31.072H27.264L33.192 0.888H54.76Z"
          fill="white"
        />
        <path
          d="M78.48 0.888L77.08 8.04H70.608L66.112 31.072H57.488L61.984 8.04H55.512L56.912 0.888H78.48Z"
          fill="white"
        />
        <path
          d="M97.656 0.431998C103.283 0.431998 107.2 2.09866 109.408 5.432L102.576 10.608C102.185 9.79093 101.569 9.10256 100.8 8.624C99.6506 8.11594 98.3994 7.88048 97.144 7.936C94.2373 7.936 91.656 9.51733 89.4 12.68C88.7553 13.7109 88.3001 14.8488 88.056 16.04C87.32 19.7733 88.472 22.4187 91.512 23.976C92.3279 24.3085 93.199 24.4849 94.08 24.496H95.032C97.96 24.224 99.504 23.6773 99.664 22.856L100.24 19.928H94.288L95.512 13.6H110.176L106.744 31.2H100.272L100.456 30.248C98.3679 31.0324 96.1823 31.5277 93.96 31.72C93.208 31.832 92.416 31.92 91.56 31.976C87.7627 31.976 84.7147 30.7973 82.416 28.44C81.1668 26.9948 80.2615 25.2853 79.768 23.44C79.0647 21.0259 78.9712 18.4751 79.496 16.016C80.344 11.664 82.8773 7.664 87.096 4.016C90.1165 1.6746 93.8343 0.412776 97.656 0.431998Z"
          fill="#00EA6A"
        />
        <path
          d="M136.376 0.952L134.976 8.104H121.688L120.8 12.68H132.96L131.696 19.152H119.536L118.608 23.896H131.896L130.48 31.136H108.88L114.776 0.952H136.376Z"
          fill="#00EA6A"
        />
        <path
          d="M156.256 19.28H165.096C163.368 23.4133 159.968 26.88 154.896 29.68C152.414 30.9273 149.674 31.5767 146.896 31.576C144.615 31.6379 142.357 31.1064 140.344 30.0336C138.33 28.9608 136.629 27.3835 135.408 25.456C133.685 22.5478 133.127 19.0947 133.848 15.792C134.915 10.256 138.251 5.82933 143.856 2.512C146.724 0.873373 149.969 0.00778137 153.272 -1.75822e-06C155.338 -0.0295015 157.384 0.404332 159.26 1.2696C161.135 2.13487 162.794 3.40958 164.112 5C165.715 7.28442 166.556 10.0174 166.512 12.808H157.84C157.687 11.8843 157.329 11.0064 156.794 10.2383C156.258 9.47022 155.558 8.83127 154.744 8.368C153.761 7.83803 152.661 7.56298 151.544 7.568C148.552 7.568 146.024 9.03467 143.96 11.968C143.203 13.0828 142.691 14.3451 142.456 15.672V15.76C142.143 16.9966 142.193 18.2973 142.6 19.5063C143.007 20.7152 143.754 21.781 144.752 22.576C145.766 23.3117 146.988 23.7042 148.24 23.696H148.928C151.781 23.712 154.224 22.24 156.256 19.28Z"
          fill="#00EA6A"
        />
        <path
          d="M178.576 0.952L176.216 13.024L188.84 0.952H198.672L184.8 13.976L193.352 31.136H183.112L177.968 19.936H177.888L174.208 23.296L172.68 31.144H164.312L170.208 0.96L178.576 0.952Z"
          fill="#00EA6A"
        />
        <path
          d="M221.728 4.704C219.179 1.62133 215.669 0.0826627 211.2 0.087996C206.669 0.107982 202.295 1.74491 198.864 4.704C195.3 7.46124 192.847 11.408 191.952 15.824C191.104 20.1707 191.957 23.88 194.512 26.952C195.952 28.7045 197.853 30.0205 200 30.752C201.6 27.552 205.184 19.688 202.128 17.816C200.736 17.304 197.8 17.816 197.8 17.816C197.8 17.816 195.312 17.016 196.504 13.272C197.696 9.528 201.232 13.272 201.232 13.272C201.232 13.272 203.632 16.152 205.072 14.488C206.512 12.824 204.36 7.776 204.36 7.776C204.36 7.776 202.76 4 206.136 3.36C209.512 2.72 208.432 7.776 208.432 7.776C208.432 7.776 207.504 13.024 209.72 13.272C211.936 13.52 213.72 8.472 213.72 8.472C213.72 8.472 215.096 5.848 217.4 8.152C219.704 10.456 215.8 13.08 215.8 13.08C215.8 13.08 211.392 15.312 211.84 17.08C212.56 19.48 216.424 19.48 216.424 19.48C216.424 19.48 220.424 19.8 218.592 24.024C216.76 28.248 213.928 22.92 213.928 22.92C213.928 22.92 211.6 21.016 210.592 21.784C207.703 24.5105 205.408 27.803 203.848 31.456C204.232 31.456 204.648 31.504 205.032 31.504C209.563 31.484 213.937 29.8471 217.368 26.888C220.934 24.1308 223.385 20.1796 224.272 15.76C225.157 11.4667 224.309 7.78133 221.728 4.704Z"
          fill="#00EA6A"
        />
      </g>
      <defs>
        <clipPath id="clip0_4266_121055">
          <rect width="224.608" height="32" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
