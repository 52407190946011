import { Typography } from '@mui/material';
import { useLocales } from 'locales';
import { loginBETGECKO } from 'overrides/images/Login';
import { BannerLoginWrapper } from './style';

// ----------------------------------------------------------------

const BannerLogin = () => {
  const { translate } = useLocales();

  return (
    <BannerLoginWrapper
      sx={{
        backgroundImage: `url(${loginBETGECKO})`,
      }}
    >
      <Typography variant="h4">{translate('welcome_bonus')}</Typography>
      <Typography component="span">
        {translate('and_get_up_to')}{' '}
        <Typography component="span">200%</Typography> {translate('rewards')}
      </Typography>
    </BannerLoginWrapper>
  );
};

export { BannerLogin };
