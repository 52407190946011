import { LogoFooterBetGecko } from 'assets/icons/LogoFooterBetGecko';

const LogoLoading = () => (
  <LogoFooterBetGecko
    disabledLink
    style={{
      width: 150,
    }}
  />
);
export { LogoLoading };
