import { RNG } from 'assets/images';
import { LOGO_LINK } from 'constants/index';
import { Link } from 'react-router-dom';

import { Typography } from '@mui/material';
import { useLocales } from 'locales';

// ----------------------------------------------------------------

const RNGLink = () => {
  const { translate } = useLocales();

  return (
    <Link to={LOGO_LINK.RNG_BETGECKO} target="_blank">
      <Typography>{translate('rng_certificated_by')}</Typography>
      <img src={RNG} alt="logo RNG" />
    </Link>
  );
};

export { RNGLink };
